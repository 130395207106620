(() => {

    const rusButton = document.querySelector('#rus');
    const engButton = document.querySelector('#eng');

    rusButton.addEventListener('click', () => {
        let rusText = document.querySelectorAll('[data-ru]');
        rusText.forEach(function(e){
            e.textContent = e.getAttribute('data-ru');
        });
    });
    engButton.addEventListener('click', () => {
        let rusText = document.querySelectorAll('[data-en]');
        rusText.forEach(function(e){
            e.textContent = e.getAttribute('data-en');
        });
    });
    
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();
            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });
        });
    });
    


    Fancybox.bind("[data-fancybox]", {
  // Your custom options
});

})();